import Logo from "@/Components/Logo";
import { Head, Link } from "@inertiajs/react";

export default function ErrorPage({
    status,
    message,
}: {
    status: number;
    message: string;
}) {
    const title = {
        503: "503: Service Unavailable",
        500: "500: Server Error",
        404: "404: Page Not Found",
        403: "403: Forbidden",
    }[status];

    const description = {
        503: "Sorry, we are doing some maintenance. Please check back soon.",
        500: "Whoops, something went wrong on our servers.",
        404: "Sorry, the page you are looking for could not be found.",
        403: "Sorry, you are forbidden from accessing this page.",
    }[status];

    return (
        <>
            <Head>
                <title>{title}</title>
                <meta name="title" content={title} />
                <meta name="description" content={description} />
            </Head>
            <main>
                <div className="container d-flex justify-content-center align-items-center vh-100">
                    <div>
                        <div className="mb-4">
                            <Logo />
                        </div>

                        <h1 className="fw-light">{title}</h1>
                        <div>{status == 403 ? message : description}</div>
                        <Link href="/" className="btn btn-warning mt-4">
                            Return to Home
                        </Link>
                    </div>
                </div>
            </main>
        </>
    );
}
